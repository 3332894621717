<template>
  <div class="users-update page-layout">
    <!-- La barre d'action -->
    <PageHeader
      title="Vos informations"
      :has-actions="true"
      :has-back="true"
      @back="onGlobalCancel"
    >
      <md-button
        class="md-primary md-raised"
        :disabled="!isInfosModified"
        @click="onGlobalSave"
      >
        Sauvegarder
      </md-button>
    </PageHeader>

    <!-- ## Les informations de l'utilisateur ## -->
    <md-card class="meep-form">
      <md-card-content>
        <!-- 	Nom d'utilisateur -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('username') }">
            <label>Nom d'utilisateur *</label>
            <md-input
              v-model="newInfos.username"
              v-validate="'required|alpha'"
              name="username"
              type="text"
            />
            <span class="md-error" v-show="errors.has('username')">{{
              errors.first("username")
            }}</span>
          </md-field>
        </div>

        <!-- Email -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>E-mail *</label>
            <md-input
              v-model="newInfos.email"
              v-validate="'required|email'"
              name="email"
              type="email"
            />
            <span class="md-error" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </md-field>
        </div>

        <div class="md-layout md-alignment-center-right">
          <md-button
            class="md-raised"
            :class="{
              'md-accent': isInfosModified,
              'meep-disabled': !isInfosModified,
            }"
            @click.native="onInfosCancel"
            :disabled="!isInfosModified"
          >
            Annuler
          </md-button>

          <md-button
            class="md-raised"
            :class="{
              'md-primary': isInfosModified,
              'meep-disabled': !isInfosModified,
            }"
            @click.native="onInfosSave"
            :disabled="!isInfosModified"
          >
            Sauvegarder
          </md-button>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :objectToActUpon="{}"
      @close="closeConfirmGlobalCancelModal"
      @confirm="goBack"
    />
  </div>
</template>

<script>
/* Modèles */
import superusersModel from "../../../model/superusers";

/* Composants */
import confirmActionModal from "../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,

      canShowToastedMessages: false,

      /* Informations de l'utilisateur */
      superuser: {},

      /* Tampons pour éditer les informations */
      newInfos: {},

      /* Si les modals sont ouverts */
      isConfirmGlobalCancelModalOpen: false,
      /* Texte des modals */
      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },
    };
  },
  methods: {
    /* ## EVENEMENTS ## */

    goBack() {
      window.history.back();
    },

    onGlobalSave() {
      if (this.isInfosModified) {
        this.onInfosSave();
      }
    },
    onGlobalCancel() {
      if (this.isInfosModified) {
        this.openConfirmGlobalCancelModal();
      } else {
        this.goBack();
      }
    },

    onInfosSave() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });

          return;
        }

        superusersModel
          .update(this.newInfos)
          .then(() => {
            this.superuser.username = this.newInfos.username;
            this.superuser.email = this.newInfos.email;

            this.$toasted.global.AppSucces({
              message: "Vos informations ont bien été mises à jour",
            });
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },
    onInfosCancel() {
      this.newInfos = {
        id: this.superuser.id,
        username: this.superuser.username,
        email: this.superuser.email,
      };

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    openConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },
    closeConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },
  },
  /* Récupérer les données de l'entreprise */
  beforeRouteEnter(to, from, next) {
    superusersModel
      .get(to.params.id)
      .then((superuser) => {
        next((vm) => {
          vm.superuser = superuser;

          vm.onInfosCancel();
          vm.canShowToastedMessages = true;
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },
  computed: {
    /* ## CONDITIONS ## */

    isInfosModified() {
      if (this.superuser.username != this.newInfos.username) return true;
      if (this.superuser.email != this.newInfos.email) return true;
      return false;
    },

    self() {
      return this.$store.state.self;
    },
  },
};
</script>

<style lang="scss" scoped>
.users-update {
  .debug-log {
    max-height: 420px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
